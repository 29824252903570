<template>
  <div>
    <Head/>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle">
        <div>{{ $fanyi("库存报告") }}&nbsp;> <span>库存预警设置</span></div>
        <div style="width: 140px" @click="$router.go(-1)">取消库存预警设置</div>
      </div>
      <div class="orderScreenContainer">
        <div class="orderScreenLeftContainer">
          <div class="inventoryWarningContainer" style="margin-bottom: 10px">
            <div class="left">
              <div class="leftItemContainer">
                <div class="label">库存预警数量：</div>
                <el-input v-model="tableData[0].amazon_reminder.forewarn_num"
                          placeholder="请输入库存预警数量" @input="forewarnNumVerify"
                          @keydown.native="channelInputLimit"/>
              </div>
              <div class="leftItemContainer" style="margin-top: 8px">
                <div class="label">库存预警通知：</div>
                <el-checkbox-group v-model="informList">
                  <el-checkbox label="站内信通知"></el-checkbox>
                  <el-checkbox label="邮件通知"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="tipsText">
              注：亚马逊实时库存数量 + RAKUMART购买进行中的商品数量 ≤ 库存预警数量<br/>
              数量满足以上条件时，才会触发库存预警<br/>
              例如：设置预警数量10，亚马逊仓库数量2，RAKUMART进行中的数量为10
            </div>
          </div>
          <div class="automaticReplenishmentContainer" style="height: 90px;">
            <div class="left">
              <div class="leftItemContainer">
                <div class="label">自动补货：</div>
                <el-radio-group v-model="tableData[0].amazon_reminder.auto_replenishment">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="2">关闭</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="tipsText">
              注：当该商品自动生成过订单,该番商品没出货完成之前，不会触发自动补货
            </div>
          </div>
          <div class="replenishmentQuantityContainer" style="padding-top: 10px">
            <div class="left">
              <div class="leftItemContainer">
                <div class="label">自动补货数量：</div>
                <el-input v-model="tableData[0].amazon_reminder.replenishment_num"
                          placeholder="请输入自动补货数量(组套商品按照默认为套数)"
                          @input="replenishmentNumVerify" @keydown.native="channelInputLimit"/>
              </div>
            </div>
            <div class="tipsText">
              注：组套商品自动补货数量按照套数进行生成<br/>
              例如：商品A 1个，同捆物A 2个，同捆物B 2个，<br/>
              设置自动补货5套，订单会自动生成商品A 5个，同捆物A 10个，同捆物B 10个，
            </div>
          </div>
        </div>
        <div class="saveButton" @click="save">保存</div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO">
            <span :title="$fanyi('状态')" class="tableHeadCon">{{ $fanyi("状态") }}</span>
          </li>
          <li class="pic">
            <span :title="$fanyi('照片')" class="tableHeadCon">{{ $fanyi("照片") }}</span>
          </li>
          <li class="submitTime">
            <span :title="$fanyi('商品名称')" class="tableHeadCon">{{ $fanyi("商品名称") }}</span>
          </li>
          <li class="goodSku">
            <span :title="$fanyi('商品SKU')" class="tableHeadCon">{{ $fanyi("商品SKU") }}</span>
          </li>
          <li class="ASINOrFNSKU">
            <span :title="$fanyi('ASIN/FNSKU')" class="tableHeadCon">{{ $fanyi("ASIN/FNSKU") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('可售量')" class="tableHeadCon">{{ $fanyi("可售量") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('不可售量')" class="tableHeadCon">{{ $fanyi("不可售量") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('出入库作业中')" class="tableHeadCon">{{ $fanyi("出入库作业中") }}</span>
          </li>
          <li class="quantityAvailable" style="line-height:30px">
            <span :title="$fanyi('RAKUMART进行中')" class="tableHeadCon">{{ $fanyi("RAKUMART进行中") }}</span>
          </li>
          <li class="quantityAvailable">
            <span :title="$fanyi('可发往亚马逊数量')" class="tableHeadCon">{{ $fanyi("可发往亚马逊数量") }}</span>
          </li>
        </ul>
        <div>
          <div v-for="(item, index) in tableData" :key="$fanyi('表格') + index"
               style="border-bottom: 1px solid #DFDFDF;">
            <ul class="obody flexAndCenter" style="border-bottom:none">
              <!--状态-->
              <li class="oNO">
                <div class="flexAndCenter">
                  <div v-for="(statusItem,statusIndex) in item.status" :key="statusIndex" class="fontSize12">
                    {{ statusItem }}
                  </div>
                </div>
              </li>
              <!--照片-->
              <li class="pic">
                <el-popover placement="right" trigger="hover">
                  <img :src="item.link"
                       style="width: 300px;height: 300px;"
                  />
                  <img slot="reference"
                       :src="item.link"
                       class="cursorPointer"
                       style="width: 50px;height: 50px;"
                  />
                </el-popover>
              </li>
              <!--商品名称-->
              <li class="submitTime">
                <div :title="item.item_name" class="u-line-3" style="width: 200px">{{ item.item_name }}</div>
              </li>
              <!--商品SKU-->
              <li class="goodSku">
                <div :title="item.sku" class="u-line-3" style="width: 100px">{{ item.sku }}</div>
              </li>
              <!--ASIN/FNSKU-->
              <li class="ASINOrFNSKU">
                <div :title="item.item_name" class="u-line-3" style="width: 140px">{{
                    item.asin
                  }}<br>/{{ item.fn_sku }}
                </div>
              </li>
              <!--可售量-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--不可售量-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--出入库作业中-->
              <li class="quantityAvailable">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--RAKUMART进行中-->
              <li class="quantityAvailable">
                <div></div>
              </li>
              <!--可发往亚马逊数量-->
              <li class="quantityAvailable">
                <div>

                </div>
              </li>
            </ul>
            <div>
              <div class="ordersListHeader">
                <div class="ordersLink">{{ $fanyi("链接") }}</div>
                <div class="goodsInfo" style="width: 334px">{{ $fanyi("商品信息") }}</div>
                <div class="goodsAttribute" style="width:254px">{{ $fanyi("商品属性") }}</div>
                <div class="goodsPrice">{{ $fanyi("进货价") }}</div>
                <div class="goodsNum">{{ $fanyi("数量") }}</div>
                <div class="goodsAttribute">{{ $fanyi("OPTION") }}</div>
                <div class="goodsAttribute">{{ $fanyi("备注") }}</div>
              </div>
              <div class="ordersListFooterList">
                <div v-for="(orderItem, orderIndex) in item.amazon_replenishment" :key="orderIndex"
                     :style="orderIndex!=item.amazon_replenishment.length-1?'margin-bottom:8px;':''"
                     class="ordersListFooterItem">
                  <div class="ordersListFooter" style="background: #FFF0DE">
                    <!--链接-->
                    <div class="ordersLink">
                      <div class="ordersLinkInfo">
                        <div class="flexAndCenter" style="margin-bottom: 17px;">
                          <div class="ordersLinkTitle">
                            {{ orderItem.product_set.length > 0 ? $fanyi("组套商品") : $fanyi("主商品") }}
                          </div>
                        </div>
                        <div class="linkContainer">
                          <el-link :href="orderItem.goods_link" :underline="false" class="u-line-2" target="_blank">
                            {{ orderItem.goods_link }}
                          </el-link>
                        </div>
                      </div>
                    </div>
                    <!--商品信息-->
                    <div class="goodsInfo">
                      <div class="goodsInfo">
                        <div class="u-line-3">补货商品：{{ orderItem.goods_title }}</div>
                        <el-popover placement="right" trigger="hover">
                          <img :src="orderItem.image"
                               style="width: 300px;height: 300px;"
                          />
                          <img slot="reference"
                               :src="orderItem.image"
                               class="cursorPointer"
                               style="width: 60px;height: 60px;"
                          />
                        </el-popover>
                      </div>
                    </div>
                    <!--商品属性-->
                    <div class="goodsAttribute" style="width:254px">
                      <div style="width: 254px;text-align: left;padding-left: 20px">
                        <el-popover placement="top" trigger="hover">
                          <div>
                            <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                          <div slot="reference">
                            <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex"
                                 class="u-line fontSize14 color333">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                        </el-popover>
                      </div>
                    </div>
                    <!--进货价-->
                    <div class="goodsPrice">{{ orderItem.price }}</div>
                    <!--数量-->
                    <div class="goodsNum">{{ orderItem.num }}</div>
                    <!--OPTION-->
                    <div class="goodsAttribute">{{ $fanyi("OPTION") }}</div>
                    <!--备注-->
                    <div class="goodsAttribute">{{ orderItem.remark }}</div>
                  </div>
                  <div style="background: #FFF8ED">
                    <div v-for="(productItem, productIndex) in orderItem.product_set" :key="productIndex">
                      <div class="ordersListFooter">
                        <!--链接-->
                        <div class="ordersLink">
                          <div class="ordersLinkInfo">
                            <el-popover placement="left" trigger="hover">
                              <div>{{ productItem.num }}个一套</div>
                              <div slot="reference" class="flexAndCenter cursorPointer" style="margin-bottom: 17px;">
                                <img alt="" src="../../../../../../assets/link.svg">
                                <div class="bundleTitle">{{ $fanyi("同捆物") }}{{ productIndex + 1 }}</div>
                              </div>
                            </el-popover>
                            <div class="linkContainer">
                              <el-link :href="productItem.goods_link" :underline="false" class="u-line-2"
                                       target="_blank">
                                {{ productItem.goods_link }}
                              </el-link>
                            </div>
                          </div>
                        </div>
                        <!--商品信息-->
                        <div class="goodsInfo">
                          <div class="goodsInfo">
                            <div class="u-line-3">补货商品：{{ productItem.goods_title }}</div>
                            <el-popover placement="right" trigger="hover">
                              <img :src="productItem.image"
                                   style="width: 300px;height: 300px;"
                              />
                              <img slot="reference"
                                   :src="productItem.image"
                                   class="cursorPointer"
                                   style="width: 60px;height: 60px;"
                              />
                            </el-popover>
                          </div>
                        </div>
                        <!--商品属性-->
                        <div class="goodsAttribute" style="width:254px">
                          <div style="width:254px;text-align: left;padding-left: 20px">
                            <el-popover placement="top" trigger="hover">
                              <div>
                                <div v-for="(detailItem, detailIndex) in productItem.detail" :key="detailIndex">
                                  {{ detailItem.key }}：{{ detailItem.value }}
                                </div>
                              </div>
                              <div slot="reference">
                                <div v-for="(detailItem, detailIndex) in productItem.detail" :key="detailIndex"
                                     class="u-line fontSize14 color333">
                                  {{ detailItem.key }}：{{ detailItem.value }}
                                </div>
                              </div>
                            </el-popover>
                          </div>
                        </div>
                        <!--进货价-->
                        <div class="goodsPrice">{{ productItem.price }}</div>
                        <!--数量-->
                        <div class="goodsNum">{{ productItem.num }}</div>
                        <!--OPTION-->
                        <div class="goodsAttribute">{{ $fanyi("OPTION") }}</div>
                        <!--备注-->
                        <div class="goodsAttribute">{{ productItem.remark }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../../../../components/head/HomePageTop.vue";

export default {
  data() {
    return {
      tableData: [],
      informList: []
    };
  },
  components: {
    Head
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      this.$api.inventoryReminderDetail({
        inventory_id: this.$route.query.id,
      }).then((res) => {
        if (res.data.amazon_reminder.is_email_inform == 1) {
          this.informList.push('邮件通知');
        }
        if (res.data.amazon_reminder.is_notifications == 1) {
          this.informList.push('站内信通知');
        }
        this.tableData = [res.data];
      });
    },
    //保存库存预警设置
    save() {
      if (this.tableData[0].amazon_reminder.auto_replenishment == 1 && this.tableData[0].amazon_reminder.replenishment_num == '') return this.$fun.showError('请输入补货数量');
      let data = {
        inventory_id: this.$route.query.id,
        is_email_inform: this.informList.includes("邮件通知") ? 1 : 0,
        is_notifications: this.informList.includes("站内信通知") ? 1 : 0,
        auto_replenishment: this.tableData[0].amazon_reminder.auto_replenishment,
        replenishment_num: this.tableData[0].amazon_reminder.replenishment_num,
        forewarn_num: this.tableData[0].amazon_reminder.forewarn_num,
        set_from_id: this.tableData[0].amazon_replenishment.id,
      }
      this.$api.inventoryReminderSave(data).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      });
    },
    replenishmentNumVerify(val) {
      this.tableData[0].amazon_reminder.replenishment_num = this.$fun.priceReplace(val);
    },
    forewarnNumVerify(val) {
      this.tableData[0].amazon_reminder.forewarn_num = this.$fun.priceReplace(val);
    },
    //输入校验
    channelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "。" || key == "." || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.downloadBtn {
  width: 100px;
  height: 34px;
  background: #2742B4;
  border-radius: 8px;
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ordersListHeader {
  display: flex;
  border: 1px solid #DFDFDF;
  background: #F6F6F6;
  border-radius: 10px;
  margin-bottom: 8px;

  div {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    height: 42px;
    line-height: 42px;
  }
}

.ordersLink, .newOrdersLink {
  width: 240px;

  a {
    width: 334px;
    color: #2742B4;
    text-decoration-line: underline;
  }
}

.goodsAttribute {
  width: 160px;

  .goodsAttributeDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    text-align: left;
    padding-left: 20px;
    position: relative;
    margin-top: 25px;
    height: 60px;
    cursor: pointer;

    .redEditIconContainer, .baiEditIconContainer {
      width: 26px;
      height: 16px;
      background: transparent;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      z-index: 10;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .baiEditIconContainer {
      display: none !important;
      background: #B4272B !important;
    }
  }

  .goodsAttributeDetails:hover {
    border: 1px dashed #B4272B;

    .redEditIconContainer {
      display: none !important;
    }

    .baiEditIconContainer {
      display: flex !important;
      background: #B4272B !important;
    }
  }

  .goodsAttributeDetailsContainer {
    width: 150px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #DFDFDF;
    cursor: pointer;
    height: 96px;
    padding: 10px;

    .optionHiddenContainer {
      height: 75px;
      overflow: hidden;
    }
  }
}

.goodsNum, .goodsPrice {
  width: 100px;
}

.newOrdersLink {
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 8px;

  .icon {
    height: 28px;
    background: #FFE5C4;
    border-radius: 4px;
    font-size: 12px;
    color: #F08804;
    padding: 0 8px;
    line-height: 28px;
    width: 100px;
  }
}

.linkContainer {
  width: 201px;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .redEditIconContainer, .baiEditIconContainer {
    width: 26px;
    height: 16px;
    background: transparent;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    z-index: 10;

    img {
      width: 12px;
      height: 12px;
    }
  }

  .baiEditIconContainer {
    display: none !important;
    background: #B4272B !important;
  }
}

.linkContainer:hover {
  border: 1px dashed #B4272B;

  .redEditIconContainer {
    display: none !important;
  }

  .baiEditIconContainer {
    display: flex !important;
    background: #B4272B !important;
  }
}

.goodsImage {
  width: 100px;
}

.goodsDetail {
  width: 200px;

  .u-line {
    font-size: 14px;
    color: #333333;
  }
}

.goodsRemark {
  width: 222px;

  div {
    font-size: 14px;
    color: #333333;
  }
}

.goodsOperation {
  width: 188px;

  .btnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;

    .addBtn {
      width: 100px;
      height: 30px;
      background: #F08804;
      border-radius: 4px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      margin-right: 15px;
    }

    .tipsBtn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #B4272B;
      cursor: pointer;
      font-size: 14px;
      color: #B4272B;
      line-height: 19px;
      text-align: center;
    }
  }
}

.ordersListFooterList {
  margin-bottom: 16px;

  .ordersListFooterItem {
    border: 1px solid #F08804;
    border-radius: 10px;
    overflow: hidden;
  }

  .ordersListFooter {
    display: flex;
    height: 120px;


    .ordersLink, .goodsInfo, .goodsPrice, .goodsNum, .goodsAttribute, .goodsOperation {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ordersLinkInfo {
      width: 240px;
      padding-left: 16px;

      .ordersLinkTitle {
        width: 68px;
        height: 28px;
        background: #FFDFB8;
        border-radius: 4px;
        line-height: 28px;
        text-align: center;
        font-size: 12px;
        color: #F08804;
      }

      .bundleTitle {
        font-size: 12px;
        color: #F08804;
        margin-left: 6px;
      }

      img {
        width: 12px;
        height: 12px;
      }
    }

    .goodsInfo {
      width: 334px;

      .goodsInfo {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;

        .u-line-3 {
          width: 274px;
          font-size: 14px;
          color: #333333;
        }

        .goodsName {
          position: relative;
          cursor: pointer;
          width: 180px;

          .redEditIconContainer, .baiEditIconContainer {
            width: 26px;
            height: 16px;
            background: transparent;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            z-index: 10;

            img {
              width: 12px;
              height: 12px;
            }
          }

          .baiEditIconContainer {
            display: none !important;
            background: #B4272B !important;
          }
        }

        .goodsName:hover {
          border: 1px dashed #B4272B;

          .redEditIconContainer {
            display: none !important;
          }

          .baiEditIconContainer {
            display: flex !important;
            background: #B4272B !important;
          }
        }

        .goodsImage {
          position: relative;
          width: 50px;
          height: 50px;

          .redEditIconContainer, .baiEditIconContainer {
            width: 26px;
            height: 16px;
            background: transparent;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            z-index: 10;

            img {
              width: 12px;
              height: 12px;
            }
          }

          .baiEditIconContainer {
            display: none !important;
            background: #B4272B !important;
          }
        }

        .goodsImage:hover {
          border: 1px dashed #B4272B;

          .redEditIconContainer {
            display: none !important;
          }

          .baiEditIconContainer {
            display: flex !important;
            background: #B4272B !important;
          }
        }
      }
    }
  }
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;
  width: 1400px;
  margin: 24px auto 0;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    div:first-child {
      font-weight: bold;
      font-size: 20px;
      color: #999999;

      span {
        color: #333333;
      }
    }

    div:last-child {
      width: 100px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid #DFDFDF;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
    }
  }

  .tabsRightContainer {
    height: 40px;
    border-bottom: 1px solid #B4272B;
    margin-top: -14px;
    flex: 1;
  }

  /deep/ .el-tabs__header {
    border-bottom: none;
  }

  /deep/ .is-active {
    border-top: 1px solid #B4272B !important;
    border-right: 1px solid #B4272B !important;
    border-left: 1px solid #B4272B !important;
    border-bottom: 1px solid transparent !important;
  }

  /deep/ .el-tabs__item {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #B4272B;
  }

  /deep/ .el-tabs__nav {
    border: none;
  }

  .orderStatusContainer {
    margin: 26px 26px 0;
    position: relative;

  }

  .orderScreenContainer {
    margin: 20px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .orderScreenLeftContainer {
      width: 1169px;
      height: 275px;
      border-radius: 10px;
      border: 1px solid #B4272B;
      padding: 15px 26px 19px;

      .inventoryWarningContainer, .automaticReplenishmentContainer, .replenishmentQuantityContainer {
        display: flex;
        align-items: center;

        .left {
          width: 456px;
          margin-right: 26px;
        }

        .leftItemContainer {
          display: flex;
          align-items: center;

          .label {
            width: 98px;
            min-width: 98px;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            text-align: right;
          }

          /deep/ .el-input__inner {
            width: 320px;
            height: 34px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #DFDFDF;
          }

          /deep/ .el-checkbox__original {
            display: none;
          }
        }

        .tipsText {
          background: #FAF2F2;
          border-radius: 10px;
          padding: 10px;
          font-size: 12px;
          color: #333333;
        }
      }

      .automaticReplenishmentContainer {
        border-top: 1px solid #DFDFDF;
        height: 64px;
        border-bottom: 1px solid #DFDFDF;
      }
    }

    .saveButton {
      width: 100px;
      height: 34px;
      background: #B4272B;
      border-radius: 6px;
      font-size: 12px;
      color: #FEFEFE;
      text-align: center;
      line-height: 34px;
      cursor: pointer;
    }
  }

  .dataBody {
    margin: 20px 26px 0;


    .pic, .receiverName, .upLoad, .orderStatus, .operation {
      width: 100px;
    }

    .datePresentation, .oNO {
      width: 120px;
    }

    .goodSku {
      width: 168px;

      .addReplenishmentBtn {
        margin-top: 20px;
        font-size: 14px;
        color: #B4272B;
        text-decoration-line: underline;
        text-align: left;
      }
    }

    .ASINOrFNSKU {
      width: 160px;
    }

    .quantityAvailable {
      width: 120px;
    }

    .submitTime {
      width: 200px;
    }

    .datePresentation {
      display: flex;
      justify-content: center;
    }

    .replenishmentLink {
      width: 188px;

      .replenishmentLinkContainer {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .replenishmentLinkNum {
          font-size: 14px;
          color: #333333;
        }

        .showChildrenBtn {
          height: 30px;
          background: #F0F0F0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          cursor: pointer;
          box-sizing: border-box;
          margin: 0 30px 0 10px;

          .el-icon-arrow-right:before,
          .el-icon-arrow-down:before {
            font-weight: bold;
            font-size: 14px;
          }

          div {
            color: #333333;
            font-size: 14px;
          }
        }

        .addLinkBtn {
          font-size: 12px;
          color: #B4272B;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }

      .inventoryWarningSetBtn {
        margin-top: 12px;
        font-size: 14px;
        color: #B4272B;
        text-decoration-line: underline;
        text-align: left;
        padding-left: 17px;
      }
    }

    .head {
      justify-content: center;
      font-size: 14px;
      color: #333;
      text-align: center;

      li {
        background: #F0F0F0;
        height: 56px;
        line-height: 56px;
      }

      li:first-child {
        border-top-left-radius: 10px;
      }

      li:last-child {
        border-top-right-radius: 10px;
      }
    }

    .obody {
      text-align: center;
      min-height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>
